






















































import { Component, Vue, Watch } from 'vue-property-decorator';
import format from '@/filter/dateFormat';
import ErrorAlert from '@/components/ErrorAlert.vue';
import MonthPicker from '@/components/form/MonthPicker.vue';

@Component({
  components: {
    ErrorAlert,
    MonthPicker
  }
})
export default class MonthViewDialog extends Vue {
  dialog: boolean = false;
  loading: boolean = false;
  error: any = null;

  month = format(Date.now(), 'yyyy-MM');
  file: string | null = null;
  format: string | null = null;

  get download(): string {
    return `monatsuebersicht-${this.month}.${this.format}`;
  }

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.month = format(Date.now(), 'yyyy-MM');

      return;
    }

    setTimeout(() => {
      this.error = null;
      this.file = null;
      this.format = null;
      this.loading = false;
      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  async save(format: 'pdf' | 'csv') {
    this.error = null;

    // @ts-ignore
    const result = await this.$refs.form.validate();

    if (!result) return;

    this.loading = true;

    try {
      const response = await this.$http.get(`api/conservatorship/month-${format}`, {
        responseType: 'blob',
        params: { month: `${this.month}-01` }
      });

      this.format = format;
      this.file = URL.createObjectURL(response.data);
    } catch (error) {
      this.error = error;
    }

    this.loading = false;
  }
}
